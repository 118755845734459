import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

const imageContext = require.context("/public/homepageImages", false, /\.(webp)$/);
const images = imageContext.keys().map(imagePath => ({
  original: imageContext(imagePath),
  thumbnail: imageContext(imagePath),
  description: imagePath,
  loading: 'lazy',
}));

function MyCarousel() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Carousel indicators={false}>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="rounded-image-homepage"
                  src={image.original}
                  alt={image.description}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default MyCarousel;
