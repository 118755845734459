import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Lottie from "lottie-react";
import animationData from '../../Assets/wedding-photo.json';
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
import Gallery from 'react-image-gallery';
import MyCarousel from "./MyCarousel";

const imageContext = require.context("/public/portfolioImages", false, /\.(webp)$/);

const images = imageContext.keys().map(imagePath => ({
  original: imageContext(imagePath),
  thumbnail: imageContext(imagePath),
  description: imagePath,
  loading: 'lazy',
}));

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              {/* <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1> */}

              <h1 className="heading-name">
                <span className="welcome-text">Welcome To</span>
                <strong className="main-name"> ODA Productions</strong>
              </h1>

              <div className="typewriter-div">
                <p style={{ color: "transparent" }}>|</p><Type />
              </div>
            </Col>

            <Col md={5}>
              <Lottie animationData={animationData} className="home__animation" />
            </Col>
              
            <div className="wave" href="https://github.com/soumyajit4419/Portfolio" >
              <div className="mobile-button-wrapper">
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <Button 
                    className="fork-btn-inner" 
                    style={{ 
                      width: '75%', 
                      height: '50px', 
                      margin: '-100px auto 0 auto', // Move the button up by increasing the negative margin-top value
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '2',
                    }}
                  >
                    <span style={{ color: "black", fontWeight: "600", fontSize: '1.45rem' }}>Let's Plan Something!</span>
                  </Button>
                </Link>
              </div>
            </div>

          </Row>
        </Container>
      </Container>
      {/* <Gallery
        id='photos'
        items={images}
        renderItem={(item) => (
          <img
            src={item.original}
            alt={item.description}
            className="rounded-image"
          />
        )}
        autoPlay={true}
        slideDuration={2000}
        showThumbnails={false}
        itemsPerRow={3}
      /> */}
      {/* <MyCarousel /> */}
      <Home2 />
    </section>
  );
}

export default Home;
