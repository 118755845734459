import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight2, ImArrowDown2 } from "react-icons/im";

function FCard() {
  const [activeSection, setActiveSection] = useState("0"); // Set the initial active section

  const handleSectionClick = (section) => {
    if (section === activeSection) {
      setActiveSection(null); // Close the section if it's already open
    } else {
      setActiveSection(section); // Open the section if it's closed or switch to another section
    }
  };

  const isSectionActive = (section) => section === activeSection;

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <ul>

            <li className="faqs-activity" onClick={() => handleSectionClick("0")} style={{ cursor: "pointer" }}>
              {isSectionActive("0") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} What services do you offer for Quinceañeras/Weddings?
            </li>
            {isSectionActive("0") && (
              <span className="faqs-description">
                You can choose from our full MemoryProduction™ Services including Photos, Videos, Blu-Ray & DVDs, Slideshows, Prints, Canvases, Photobooks, Videobooks, 
                Guest Books, Photobooth & 360 Photobooth, and much more!
              </span>
            )}

            <li className="faqs-activity" onClick={() => handleSectionClick("1")} style={{ cursor: "pointer" }}>
              {isSectionActive("1") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} What Photobooth options do you offer?
            </li>
            {isSectionActive("1") && (
              <span className="faqs-description">
                1. Our StudioBooth™ with a DSLR Photo-Camera, Pro Lighting, GIFs, and Instant 4x6 Prints! <br />
                2. Our 360 Photobooth with a DSLR Video-Camera, Custom Overlays/Filters, and Instant Sharing! <br />
              </span>
            )}

            <li className="faqs-activity" onClick={() => handleSectionClick("2")} style={{ cursor: "pointer" }}>
              {isSectionActive("2") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Can we request specific music or songs for the video soundtrack?
            </li>
            {isSectionActive("2") && (
              <span className="faqs-description">
                Yes, you can provide specific music preferences for your video, or we can suggest options based on the mood you want to create.
              </span>
            )}

            <li className="faqs-activity" onClick={() => handleSectionClick("3")} style={{ cursor: "pointer" }}>
              {isSectionActive("3") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Can we order physical prints or albums?
            </li>
            {isSectionActive("3") && (
              <span className="faqs-description">
                Yes! We offer prints on Wood, Metal, Canvas, Magnets, Pillows, Blankets, and much more! 
                Feel free to <a style={{ textDecoration: 'underline', cursor: 'pointer', color: '#fff200' }} href="/contact">Contact Us</a> about any custom prints!
              </span>
            )}

            <li className="faqs-activity" onClick={() => handleSectionClick("4")} style={{ cursor: "pointer" }}>
              {isSectionActive("4") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} What is the backup plan for adverse weather conditions?
            </li>
            {isSectionActive("4") && (
              <span className="faqs-description">
                Our comprehensive backup plans include backup indoor locations, weather-resistant equipment, and experienced staff ready to adapt to changing conditions.
                Your event's success is our top priority, and we're committed to providing a flawless experience, rain or shine.
              </span>
            )}

          </ul>

          <p style={{ color: "#f2ff00 " }}>
            "Turning moments into lifelong memories."
          </p>
          <footer className="blockquote-footer">Andre (DP)</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default FCard;
