import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillYoutube,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Gallery from 'react-image-gallery';
import YouTube from 'react-youtube';

const imageContext = require.context("/public/portfolioImages", false, /\.(webp)$/);
const videoContext = require.context("../../../public/homepageVideos", false, /\.(mp4|mov|webm)$/);

const images = imageContext.keys().map(imagePath => ({
  original: imageContext(imagePath),
  thumbnail: imageContext(imagePath),
  description: imagePath,
  loading: 'lazy',
}));

const videos = videoContext.keys().map((videoPath, index) => ({
  src: videoContext(videoPath),
  index: index + 1,
}));

const youtube_options = {
  width: '100%',
  height: '100%',
  playerVars: {
    autoplay: 0,
    mute: 0,
  },
};

function Home2() {

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>

      <h1>One of Our Recent</h1>
      <h1><strong className="purple"> Wedding Films</strong></h1>
      <Gallery
        id="videos"
        items={videos}
        showThumbnails={false}
        showIndex={false}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={true}
        renderItem={(item) => (
          <div className="video-container" key={item.index}>
            <video loop autoPlay muted controls className="rounded-video">
              <source src={item.src} type="video/mp4" />
              Your browser does not support videos! Please contact us and let us know you got this error!
            </video>
          </div>
        )}
      />

      <br></br><br></br>
      <h1>Our Most Recent</h1>
      <h1><strong className="purple"> Quinceañera Film</strong></h1>
      <Gallery
        id="videos"
        items={videos}
        showThumbnails={false}
        showIndex={false}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={true}
        renderItem={(item) => (
          <div className="youtube-home-container">
            <YouTube videoId='CXaNMNUjx9k' opts={youtube_options}/>
          </div>
        )}
      />

        <Link to="/project" style={{ textDecoration: 'none', zIndex: '2', justifyContent: 'center', display: 'flex' }}>
          <Button 
            className="fork-btn-fixed" 
            style={{ 
              width: '85%', 
              height: '50px', 
              margin: '20px auto -20px auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1',
              }}
          >
            <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>View Our Full Portfolio</span>
          </Button>
        </Link>
      </Container>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET US <span className="purple"> INTRODUCE </span> OURSELVES
            </h1>
            <p className="home-about-body">
              We are a team of travelling Photographers, Cinematographers, and Editors who fell in love with Photo & Video Production!
              <br />
              <br />
              Based out of El Paso, we take pride in documenting your special day the best we can! If you are outside of El Paso, don't worry! We love to travel!
              <br />
              <br />
              We create heart-warming memory films for your special event: Weddings, Quinceaneras, Anniversaries, First Birthdays, etc.
              <br />
              <br />
              We offer full Memory Production with &nbsp;
              <b className="purple">Photos, Videos, Prints, and Photobooths </b>
              to ensure that every moment is cherished {" "}
              <b className="purple">
                forever.
              </b>
              <br />
              <br />
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <Button 
                    className="fork-btn-inner" 
                    style={{ 
                      width: '85%', 
                      height: '50px', 
                      margin: '0 auto 0 auto', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '99',
                    }}
                    >
                    <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>Let's Discuss Your Dream Day</span>
                </Button>
              </Link>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Gallery
              id='photos'
              items={images}
              renderItem={(item) => (
                <img
                src={item.original}
                alt={item.description}
                className="rounded-image-homepage"
                />
              )}
              autoPlay={true}
              slideDuration={2000}
              showThumbnails={false}
              showNav={false}
              showFullscreenButton={false}
              showPlayButton={false}
              />
          </Col>
        </Row>

        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>Feel free to <span className="purple">connect </span> with us</p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.youtube.com/@odaproductions2954"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  >
                  <AiFillYoutube />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/odaproductions915"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/odaproductions"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://m.me/odaproductions915"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  >
                  <FaFacebookMessenger />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
