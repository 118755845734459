import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle from "../Particle";
import FCard from "./FCard";
import { AiOutlineMail, AiOutlinePhone, AiFillInstagram } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";

function FAQs() {
    return (
        <Container fluid className="about-section">
            <Particle />
            <Container>
            <Row style={{ justifyContent: 'center' }}>
                <h1 className="projects__heading-name">
                    <strong className="main-name"> Frequently Asked Questions</strong>
                </h1>
            </Row>

            <Col>
                Send Us Your <strong className="FAQs__gold">Questions!</strong>
            </Col>

            <Row style={{ justifyContent: 'center' }}>
                <Col>
                    <ul className="home-about-social-links">
                        <li className="social-icons">
                        <a
                            href="tel:+19154007796"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour home-social-icons"
                        >
                            <AiOutlinePhone />
                        </a>
                        </li>
                        <li className="social-icons">
                        <a
                            href="mailto:odaproductions915@gmail.com"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour home-social-icons"
                        >
                            <AiOutlineMail />
                        </a>
                        </li>
                    </ul>
                    <ul className="home-about-social-links">
                        <li className="social-icons">
                        <a
                            href="https://m.me/odaproductions915"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour home-social-icons"
                        >
                            <FaFacebookMessenger />
                        </a>
                        </li>
                        <li className="social-icons">
                        <a
                            href="https://instagram.com/odaproductions"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour home-social-icons"
                        >
                            <AiFillInstagram />
                        </a>
                        </li>
                    </ul>
                </Col>
            </Row>

            <FCard />

            <Row style={{ justifyContent: "center", padding: "10px", marginBottom: "0" }}>

                <Link to="/contact" style={{ textDecoration: 'none', zIndex: '2', justifyContent: 'center', display: 'flex' }}>
                    <Button 
                        href="/contact" 
                        className="fork-btn-inner" 
                        style={{ 
                        width: '85%', 
                        height: '50px', 
                        margin: '0px auto 50px auto', 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '2',
                        }}
                    >
                        <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>Let's Plan It!</span>
                    </Button>
                </Link>
            </Row>

            {/* <h1 className="project-heading">
            Our Happy <strong className="purple">Clients! </strong>
            </h1> */}

            </Container>
        </Container>
    );
}

export default FAQs;
