import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import { Link } from 'react-router-dom';

import Lottie from "lottie-react";
import animationData from "./icons/animation_lnr3znmq.json";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px", marginBottom: "-100px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Get To Know <strong className="purple">Our Team</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <Lottie animationData={animationData} className="about__animation" style={{ maxWidth: '600px'}} />
          </Col>
          <Link to="/contact" style={{ textDecoration: 'none', zIndex: '2' }}>
            <Button 
              className="fork-btn-inner" 
              style={{ 
                width: '85%', 
                height: '50px', 
                margin: '-150px auto 50px auto', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '2',
              }}
            >
              <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>Book a Conversation</span>
            </Button>
          </Link>
        </Row>

        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          Other <strong className="purple">Tools</strong> We Use
        </h1>
        <Toolstack />

        {/* <Github /> */}
      </Container>
    </Container>
  );
}

export default About;
