import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Particle from "../Particle";
import { AiOutlineMail, AiOutlinePhone, AiFillInstagram } from "react-icons/ai";
import { FaFacebookMessenger, FaRegFrownOpen } from "react-icons/fa";
import Gallery from 'react-image-gallery';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';

const imageContext = require.context("/public/photoboothImages", false, /\.(jpg)$/);

const images = imageContext.keys().map(imagePath => ({
  original: imageContext(imagePath),
  thumbnail: imageContext(imagePath),
  description: imagePath,
  loading: 'lazy',
}));

function Photobooth() {
    return (
        <Container fluid className="about-section">
            <Particle />
            <Container>
                <Row style={{ justifyContent: 'center' }}>
                    <h1 className="projects__heading-name">
                        <strong className="main-name"> Photobooth</strong>
                    </h1>
                </Row>

                <h2>Our <strong className="purple">StudioBooth™</strong> With Prints</h2>
                <Row style={{ justifyContent: 'center' }}>
                    <Gallery
                        id='photos'
                        items={images}
                        renderItem={(item) => (
                            <img
                                src={item.original}
                                alt={item.description}
                                className="rounded-image"
                            />
                        )}
                        autoPlay={true}
                        slideDuration={2000}
                    />
                </Row>

                <Row style={{ justifyContent: "center", padding: "10px", marginBottom: "0" }}>
                    <Link to="/contact" style={{ textDecoration: 'none', zIndex: '2', justifyContent: 'center', display: 'flex' }}>        
                        <Button 
                            href="/contact" 
                            className="fork-btn-inner" 
                            style={{ 
                            width: '85%', 
                            height: '50px', 
                            margin: '0px auto 50px auto', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '2',
                            }}
                        >
                            <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>Plan Your Photobooth Event!</span>
                        </Button>
                    </Link>
                </Row>

                <h2>Our <strong className="purple">360 Photobooth</strong></h2>
                <div className="youtube-container">
                    <YouTube videoId="ZiE3T0DbVnc" />
                </div>

                <Row style={{ justifyContent: "center", padding: "10px", marginBottom: "0" }}>
                    <Link to="/contact" style={{ textDecoration: 'none', zIndex: '2', justifyContent: 'center', display: 'flex' }}>
                        <Button 
                            href="/contact" 
                            className="fork-btn-inner" 
                            style={{ 
                            width: '85%', 
                            height: '50px', 
                            margin: '0px auto 50px auto', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '2',
                            }}
                        >
                            <span style={{ color: "black", fontWeight: "600", fontSize: '1.2em' }}>Plan Your 360 Photobooth Event!</span>
                        </Button>
                    </Link>
                </Row>

                <Row style={{ justifyContent: 'center' }}>
                    <Col>
                        <span style={{ fontSize: '1.25rem' }}>Send Us Your <strong className="FAQs__gold" style={{ fontSize: '1.25rem' }}>Questions!</strong></span>
                    </Col>
                </Row>

                <Row style={{ justifyContent: 'center' }}>
                    <Col>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                            <a
                                href="tel:+19154007796"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour home-social-icons"
                            >
                                <AiOutlinePhone />
                            </a>
                            </li>
                            <li className="social-icons">
                            <a
                                href="mailto:odaproductions915@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour home-social-icons"
                            >
                                <AiOutlineMail />
                            </a>
                            </li>
                        </ul>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                            <a
                                href="https://m.me/odaproductions915"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour home-social-icons"
                            >
                                <FaFacebookMessenger />
                            </a>
                            </li>
                            <li className="social-icons">
                            <a
                                href="https://instagram.com/odaproductions"
                                target="_blank"
                                rel="noreferrer"
                                className="icon-colour home-social-icons"
                            >
                                <AiFillInstagram />
                            </a>
                            </li>
                        </ul>
                    </Col>
                </Row>

                {/* <h1 className="project-heading">
                Our Happy <strong className="purple">Clients! </strong>
                </h1> */}

            </Container>
        </Container>
    );
}

export default Photobooth;
