import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight2, ImArrowDown2 } from "react-icons/im";

function AboutCard() {
  const [activeSection, setActiveSection] = useState("Stress-Free Planning"); // Set the initial active section

  const handleSectionClick = (section) => {
    if (section === activeSection) {
      setActiveSection(null); // Close the section if it's already open
    } else {
      setActiveSection(section); // Open the section if it's closed or switch to another section
    }
  };

  const isSectionActive = (section) => section === activeSection;

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left", wordSpacing: "4px" }}>
            Our event photo and video team loves capturing the essence of Quinceañeras and Weddings, preserving cherished memories with expertise and creativity.
          </p>
          <ul>
            <strong className="purple" style={{ display: 'block', textAlign: 'left' }}>What Sets Our Team Apart</strong>

            <li className="about-activity" onClick={() => handleSectionClick("Stress-Free Planning")} style={{ cursor: "pointer", textDecoration: "underline" }}>
              {isSectionActive("Stress-Free Planning") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Stress-Free Planning
            </li>
            {isSectionActive("Stress-Free Planning") && (
              <span className="about-description">
                We strive to make the planning process as stress-free as possible, offering guidance, support, and a calming presence on your big day.
              </span>
            )}

            <li className="about-activity" onClick={() => handleSectionClick("Creative Storytelling")} style={{ cursor: "pointer", textDecoration: "underline"  }}>
              {isSectionActive("Creative Storytelling") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Creative Storytelling
            </li>
            {isSectionActive("Creative Storytelling") && (
              <span className="about-description">
                We consider ourselves visual storytellers, dedicated to bringing your narrative to life through creative, timeless, and emotive content.
              </span>
            )}

            <li className="about-activity" onClick={() => handleSectionClick("Embracing Diversity")} style={{ cursor: "pointer", textDecoration: "underline"  }}>
              {isSectionActive("Embracing Diversity") ? (
                <ImArrowDown2 /> // Show this icon when the section is active
              ) : (
                <ImArrowRight2 /> // Show this icon when the section is not active
              )} Embracing Diversity
            </li>
            {isSectionActive("Embracing Diversity") && (
              <span className="about-description">
                We appreciate the diversity of cultures and backgrounds and take pride in representing this diversity in our work, creating inclusive and welcoming atmospheres.
              </span>
            )}
          </ul>

          <p style={{ color: "#f2ff00 " }}>
            "We craft memories one shot at a time."
          </p>
          <footer className="blockquote-footer">Orlando (Owner)</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
